import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { auth } from "../Firebase.js";
// import { AuthContext } from "../contexts/Auth";
import { signOut } from "firebase/auth";


import "../styles/navbar.css";

const HomeNavbar = () => {
  let isUserLoggedIn = auth.currentUser;
  // const { userInfo } = useContext(AuthContext);

  if (isUserLoggedIn) {
    return (
      <>
        <nav class="navbar navbar-expand-lg navbar-dark d-flex justify-content-between align-items-center">
          <Navbar.Brand id="app_name" href="/">
            <img
              alt=""
              src="/logo192.png"
              width="50"
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          {/* <Navbar.Text>
              {userInfo.autogroup ? userInfo.autogroup : null}
            </Navbar.Text> */}

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  style={{ textDecoration: "none" }}
                >
                  Home
                </Link>
              </li>
              <li class="nav-item ">
                <Link
                  className="nav-link"
                  to="/reports"
                  style={{ textDecoration: "none" }}
                >
                  Counts
                </Link>
              </li>
              <li class="nav-item ">
                <Link
                  className="nav-link"
                  to="/count"
                  style={{ textDecoration: "none" }}
                >
                  Reports
                </Link>
              </li>
              <li class="nav-item ">
                <Link
                  className="nav-link"
                  to="/exceptions"
                  style={{ textDecoration: "none" }}
                >
                  Exceptions
                </Link>
              </li>
              
            </ul>
          </div>

          <Nav className="justify-content-end ms-auto">
            <Link
              className="navbar-link"
              onClick={() => {
                signOut(auth);
              }}
              to="/login"
              style={{ textDecoration: "none" }}
            >
              Logout
              <img src="icons/icon-log-out.svg" alt="" />
            </Link>
          </Nav>
        </nav>
      </>
    );
  } else {
    return null;
  }
};

export default HomeNavbar;
