import React, { useEffect, useState, useContext } from "react";
import { Container, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  query,
  limit,
  writeBatch,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { AuthContext } from "../contexts/Auth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import "../styles/count.css";
import "../styles/deletebutton.css";
import searchIcon from "../images/icons/icon-search-primary.svg";
import downloadIcon from "../images/icons/icon-upload-primary.svg";

function Count() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [showDeleteLoading, setShowDeleteLoading] = useState(false);

  const [unders, setUnders] = useState([]);
  const [overs, setOvers] = useState([]);

  const [calculatedExceptions, setCalculatedExceptions] = useState(true);

  const [exceptVins, setExceptVins] = useState([]);

  const [globalFilter, setGlobalFilter] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const [hasReport, setHasReport] = useState(false);

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const handleDeleteWarningClose = () => setShowDeleteWarning(false);

  var today = new Date();
  const date =
    today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();

  const exceptCols = [
    { field: "vin", header: "Vin" },
    { field: "locationUnder", header: "Under Location" },
    { field: "locationOver", header: "Over Location" },
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "cost", header: "Cost" },
  ];

  const undersCols = [
    { field: "vin", header: "Vin" },
    { field: "locationUnder", header: "Location" },
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "cost", header: "Cost" },
  ];

  const oversCols = [
    { field: "vin", header: "Vin" },
    { field: "locationOver", header: "Location" },
  ];

  const exceptColumns = exceptCols.map((col, i) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const undersColumns = undersCols.map((col, i) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const oversColumns = oversCols.map((col, i) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  useEffect(() => {
    if (userInfo !== undefined) {
      checkExceptions();
    } else {
      //show error msg
      setFetchDB(false);
      console.log("error");
    }

    if (overs.length > 0 && unders.length > 0 && calculatedExceptions) {
      setFetchDB(true);
      setLoadingText("Calculating Exceptions...");
      createExceptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, overs, unders, calculatedExceptions]);

  async function checkExceptions() {
    const docRef = doc(db, "orgs", userInfo.org);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().exceptionCompleted) {
        setHasReport(true);
        getUnders();
      } else {
        setFetchDB(false);
        console.log("error");
      }
    } else {
      setFetchDB(false);
      console.log("error");
    }
  }

  async function getUnders() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "undersReport");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    setUnders(items);
    getOvers();
  }

  async function getOvers() {
    const items = [];

    const docRef = collection(db, "orgs", userInfo.org, "oversReport");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    setOvers(items);
  }

  function createExceptions() {
    setCalculatedExceptions(false);

    console.log("Overs: ", overs);
    console.log("Unders: ", unders);

    const foundMatches = unders.filter((x) =>
      overs.some((y) => y.vin === x.vin)
    );

    foundMatches.map((foundMatchesItem) => {
      var itemFromOvers = overs.find(
        (overItem) => overItem.vin === foundMatchesItem.vin
      );

      if (itemFromOvers) {
        foundMatchesItem.locationOver = itemFromOvers.locationOver;
        console.log("Match Found!: " + foundMatchesItem.vin);
      }

      //ADD TO REMOVE - Array.prototype.map() expects a return value from arrow function 
      return itemFromOvers; //MAY NEED TO CHANGE/DELETE 
      //************************************ */
      
    });

    setExceptVins(foundMatches);
    setFetchDB(false);
  }

  const exportExcel = (exportArray, filename) => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, filename);
    });
  };

  function saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + date + EXCEL_EXTENSION);
    });
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  function setupDeleteOrgCount() {
    const time = getTodaysTime();
    const date = getTodaysDate();

    const oversRef = collection(db, "orgs", userInfo.org, "oversReport");
    const q_overs = query(oversRef, limit(overs.size));

    const undersRef = collection(db, "orgs", userInfo.org, "undersReport");
    const q_unders = query(undersRef, limit(unders.size));

    // setFetchDB(true);
    setShowDeleteWarning(false);
    // setLoadingText("Deleting Exceptions Report...\nThis may take a few minutes...");
    setShowDeleteLoading(true);
    return new Promise((resolve) => {
      deleteCountCollection(db, q_overs, overs.size, resolve).then(function () {
        deleteCountCollection(db, q_unders, unders.size, resolve).then(
          function () {
            const orgDetailsRef = doc(db, "orgs", userInfo.org);
            updateDoc(orgDetailsRef, {
              except_num_of_overs: 0,
              except_num_of_unders: 0,
              countCompleted: false,
              exceptionCompleted: false,
              pc_date_updated: date,
              pc_time_updated: time,
              except_date_deleted: date,
            }).then(function () {
              setExceptVins([]);
              setFetchDB(false);
              history.go(0);
            });
          }
        );
      });
    });
  }

  //Delete Collection
  async function deleteCountCollection(db, query, batchSize, resolve) {
    const snapshot = await getDocs(query);

    let numDeleted = 0;
    if (snapshot.size > 0) {
      // Delete documents in a batch
      console.log("deleting VIN");
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      numDeleted = snapshot.size;
    }

    if (numDeleted < batchSize) {
      resolve();
      return;
    }

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    setTimeout(() => {
      deleteCountCollection(db, query, batchSize, resolve);
    }, 0);
  }
  //End Delete Collection

  const showDeleteModal = () => {
    setShowDeleteWarning(true);
  };

  if (showDeleteWarning) {
    return (
      <Modal
        size="lg"
        show={showDeleteWarning}
        onHide={handleDeleteWarningClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Exceptions Reports for {userInfo.org}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will not the Count Reports for the indivdual Lots.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={handleDeleteWarningClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={setupDeleteOrgCount}
          >
            Delete Exceptions Report
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const emptyExceptionsMessage = () => {
    if (exceptVins.length === 0) {
      return (
        <div className="md-4 text-center">
          <h4>Report is Empty</h4>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={() => history.push("/")}
          >
            Home
          </button>
        </div>
      );
    } else {
      return (
        <div className="md-4 text-center">
          <h4>No Results Found</h4>
        </div>
      );
    }
  };

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  if (showDeleteLoading) {
    return (
      <div class="cont">
        <div class="paper"></div>
        <button class="deletingBtn">
          <div class="loader">
            <div class="deleteone"></div>
            <div class="deleteone"></div>
            <div class="deleteone"></div>
            <div class="deleteone"></div>
            <div class="deleteone"></div>
            <div class="deleteone"></div>
          </div>
          Deleting
        </button>
        <div class="g-cont">
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
          <div class="garbage"></div>
        </div>
      </div>
    );
  }

  const countHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">
            {userInfo.org} Exceptions
          </h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {exceptVins.length}
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <button
            type="button"
            className="btn btn-primary w-full py-9px"
            disabled={!hasReport}
            style={{ minWidth: 313 }}
            onClick={showDeleteModal}
          >
            Delete Reports
          </button>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(exceptVins, "Found_Exceptions_Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const undersHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">
            {userInfo.org} Unders Report
          </h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {unders.length}
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <button
            type="button"
            className="btn btn-primary w-full py-9px"
            disabled={!hasReport}
            style={{ minWidth: 313 }}
            onClick={showDeleteModal}
          >
            Delete Reports
          </button>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(unders, "Unders Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const oversHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">
            {userInfo.org} Overs Report
          </h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {overs.length}
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <button
            type="button"
            className="btn btn-primary w-full py-9px"
            disabled={!hasReport}
            style={{ minWidth: 313 }}
            onClick={showDeleteModal}
          >
            Delete Reports
          </button>
        </div>
      </div>

      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(overs, "Overs Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const mainHeader = (
    <>
      <div class="d-flex justify-content-md-between flex-wrap text-center text-md-left physical-count-datatable">
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">Exceptions Reports</h4>
          <p class="report-subtext mt-1">
            Use the tabs to switch between reports.
          </p>
        </div>

        <div class="d-flex align-items-center mt-3 mt-md-0">
          <div
            class="form-group has-search w-100 mb-0"
            style={{ maxWidth: 373 }}
          >
            <img src={searchIcon} class="form-control-feedback" alt="" />
            <input
              type="text"
              class="form-control form-search"
              placeholder="Search..."
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );

  const foundExceptionClass = (data) => {
    if(exceptVins.some(e=> e.vin === data.vin)){
      return 'bg-hightlight';
    }
  };

  return (
    <Container>
      {mainHeader}

      <div className="physical-count-datatable">
        <div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel id="exceptTablePanel" header="Found Exceptions">
              <div class="exceptionsTable">
                <DataTable
                  id="exceptionTabTable"
                  value={exceptVins}
                  paginator
                  stripedRows 
                  rows={10}
                  header={countHeader}
                  globalFilter={globalFilter}
                  emptyMessage={emptyExceptionsMessage}
                >
                  {exceptColumns}
                </DataTable>
              </div>
            </TabPanel>
            <TabPanel header="Unders">
              <DataTable
                id="exceptionTabTable"
                rowClassName={foundExceptionClass}
                value={unders}
                paginator
                stripedRows 
                rows={25}
                header={undersHeader}
                globalFilter={globalFilter}
                emptyMessage={emptyExceptionsMessage}
              >
                {undersColumns}
              </DataTable>
            </TabPanel>
            <TabPanel header="Overs">
              <DataTable
                id="exceptionTabTable"
                rowClassName={foundExceptionClass}
                value={overs}
                paginator
                stripedRows 
                rows={25}
                header={oversHeader}
                globalFilter={globalFilter}
                emptyMessage={emptyExceptionsMessage}
              >
                {oversColumns}
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </Container>
  );
}

export default Count;
