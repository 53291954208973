import React, { useContext, useEffect, useState } from "react";
import { Container, Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/Auth.js";
import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  writeBatch,
} from "firebase/firestore";

import { ExcelRenderer } from "react-excel-renderer";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

// FOR TEKION
// import axios from "axios";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/count.css";
import "../styles/exceptions.css";

import downloadIcon from "../images/icons/icon-upload-primary.svg";
import infoIcon from "../images/icons/icon-info.svg";

function Exceptions() {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [lots, setLots] = useState([]);

  const [vins, setVins] = useState([]);
  const [inventory, setInventory] = useState([]);

  const [location, setLocation] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [lastUpdated, setLastUpdated] = useState("N/A");

  // const [globalFilter, setGlobalFilter] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [subHeader, setSubHeader] = useState(
    "Select an Autogroup and Input a DMS File" //"or pull from Inventory"
  );
  const [unders, setUnders] = useState([]);
  const [overs, setOvers] = useState([]);

  const [countReady, setCountReady] = useState(true);

  const [showExcelError, setShowExcelError] = useState(false);

  // FOR TEKION
  // const [inventoryReady, setInventoryReady] = useState(true);

  const [showEntryDivs, setEntryDivs] = useState(true);
  const [showCalcDiv, setShowCalcDiv] = useState(false);
  const [showExcelDiv, setShowExcelDiv] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const [showSaveExceptions, setShowSaveExceptions] = useState(false);
  const [showCalculateExceptions, setShowCalculateExceptions] = useState(false);

  const countCols = [
    { field: "vin", header: "Vin" },
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "date", header: "Date" },
    { field: "locOrigin", header: "Location" },
    { field: "cost", header: "Cost" },
  ];

  const invCols = [
    { field: "vin", header: "VIN" },
    { field: "make", header: "Make" },
    { field: "model", header: "Model" },
    { field: "year", header: "Year" },
    { field: "cost", header: "Cost" },
  ];

  const oversCols = [
    { field: "vin", header: "Vin" },
    { field: "date", header: "Date" },
    { field: "locOrigin", header: "Location" },
  ];

  const oversColumns = oversCols.map((col) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const countColumns = countCols.map((col) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  const inventoryColumns = invCols.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
      ></Column>
    );
  });

  useEffect(() => {
    setFetchDB(true);
    setLoadingText("Loading...");

    if (userInfo !== undefined) {
      setFetchDB(true);
      setLoadingText("Loading Locations...");
      getLots();
    }

    if (countReady && location && location.length > 0) {
      setFetchDB(true);
      setLoadingText("Fetching Count Report...");
      hasCountReport();
    }

    // if (
    //   inventoryReady &&
    //   inventoryId &&
    //   inventoryId.length &&
    //   vins &&
    //   vins.length
    // ) {
    //   setFetchDB(true);
    //   setLoadingText("Fetching Inventory Report...");
    //   authInventory();
    // }

    if (inventory && inventory.length) {
      setFetchDB(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, location, vins, inventoryId, countReady]);
  //useEffect has missing dependencies:
  // 'authInventory', 'countReady', 'getLots', 'hasCountReport', 'inventory', and 'inventoryReady'.

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  async function getLots() {
    const lotItems = [];

    const docDateRef = doc(db, "orgs", userInfo.org);
    const docDateSnap = await getDoc(docDateRef);

    if (docDateSnap.exists()) {
      // setLastUpdated(docDateSnap.data().except_date_deleted);
      setLastUpdated(getLastCleared(docDateSnap.data().except_date_deleted));
    } else {
      console.log("No such document!");
    }

    const docRef = collection(db, "orgs", userInfo.org, "lots");
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      lotItems.push(doc.data());
      // console.log("Lot Data:", doc.data());
    });

    setFetchDB(false);
    setLots(lotItems);
  }

  const handleLocation = (loc) => {
    console.log("Setting Location - " + loc);
    setLocation(loc);
    setSubHeader(loc);
    setEntryDivs(false);
    setShowCalcDiv(true);
  };

  async function hasCountReport() {
    setCountReady(false);
    const docRef = doc(db, "orgs", userInfo.org, "lots", location);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().countCompleted) {
        if (docSnap.data().id) {
          getLocationReport(0);

          /***********************************
           * TODO --- TEKION API WITH DEALER ID
           * //Get inventory from Tekion
           * getLocationReport(docSnap.data().id);
           *
           *************************************/
        } else {
          getLocationReport(0); //Loads Excel Input
        }
      } else {
        console.log("no count report found");
      }
    } else {
      console.log("no data exists");
    }
  }
  async function getLocationReport(id) {
    const countItems = [];
    const vinsRef = collection(
      db,
      "orgs",
      userInfo.org,
      "lots",
      location,
      "countReport"
    );

    const querySnapshot = await getDocs(vinsRef);
    querySnapshot.forEach((doc) => {
      countItems.push(doc.data());
      setFetchDB(true);
    });
    setVins(countItems);

    if (id === 0) {
      //SET EXCEL CARD
      setShowExcelDiv(true);
    } else {
      setInventoryId(id);
    }
  }

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    var excelItems = [];
    var headers = [];

    var excelFlag = false;
    // setDMSfileName(fileObj.name);

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        //Col Headers
        const temparr = resp.rows.at(4);
        for (var i = 0; i < temparr.length; i++) {
          let col = {
            header: temparr[i],
            field: temparr[i],
          };
          headers.push(col);
        }
        //VINS
        for (var j = 5; j < resp.rows.length - 1; j++) {
          excelItems.push(resp.rows.at(j));
        }
        //Clean Excel
        excelItems = excelItems.filter((e) => e.length > 2);

        //Clean Headers
        headers = cleanExcelHeaders(headers);

        excelItems = createObjectArr(headers, excelItems);

        excelItems = excelItems.filter(function (element) {
          return element.vin !== undefined;
        });

        // setDMS_headers(headers);
        //ERROR CHECK HERE
        console.log(excelItems);

        excelItems.forEach((item) => {
          if (item.vin === null || undefined) {
            excelFlag = true;
          } else if (item.vin.length !== 6) {
            excelFlag = true;
          }
        });

        if (excelFlag) {
          //Error in Excel File
          console.log("ERROR, Check Excel");
          setShowExcelError(true);
        } else {
          //No Errors for VINs
          console.log("Excel Vins:", excelItems);

          setInventory(excelItems);
          setShowExcelDiv(false);
          setShowCalculateExceptions(true);
          setShowCalcDiv(true);
        }
      }
    });
  };

  const handleExcelErrorClose = () => {
    setShowExcelError(false);
    history.push("/exceptions");
  };

  if (showExcelError) {
    return (
      <Modal
        size="lg"
        show={showExcelError}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Error Uploading Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please check if all entries for the VIN column are valid.<br></br>
          For more information, please click the "?" in the Upload Box.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={handleExcelErrorClose}
          >
            Return
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  function cleanExcelHeaders(propNames) {
    propNames.forEach(function (col) {
      if (typeof col.header === "undefined" || col.field === "undefined") {
        col.header = "";
        col.field = "";
      }
    });
    propNames.splice(0, 1);
    propNames.splice(5, 4);
    return propNames;
  }

  //Change based on DMS excel
  function createObjectArr(propNames, values) {
    const json = values.map(function (vehicle) {
      return {
        vin: vehicle[1],
        stockNo: vehicle[2],
        make: vehicle[3],
        model: vehicle[4],
        year: vehicle[5],
        cost: vehicle[11] || 0,
      };
    });
    return json;
  }

  /*****************
   * 
  async function authInventory() {
    setInventoryReady(false);
    const qs = require("querystring");
    const data = {
      "access-key": "aadd8b6ca43344328305b0a8e02216dc",
      "secret-key": "mwuiP38L!lCdfZ2y",
    };

    const options = {
      method: "POST",
      headers: {
        client_id: "6013ca1b80964f7b9b147e23f2f1a46e",
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      url: "https://openapi.tekioncloud.com/auth/v1/oauth2/token",
    };

    axios
      .request(options)
      .then(function (res) {
        var items = [];
        var nextPageKey = "";
        console.log("Connecting to Tekion API...");
        getAllInventoryFromAPI(
          res.data.access_token,
          nextPageKey,
          items,
          inventoryId,
          location
        );
      })
      .catch(function (err) {
        console.log("error = " + err);
      });
  }

  async function getAllInventoryFromAPI(
    key,
    nextPageKey,
    items,
    id,
    autogroup
  ) {
    var tekionURL = "";

    if (nextPageKey) {
      tekionURL =
        "https://openapi.tekioncloud.com/api/v2/vehicle/inventory?nextPageKey=" +
        nextPageKey;
    } else {
      tekionURL = "https://openapi.tekioncloud.com/api/v2/vehicle/inventory";
    }

    const options = {
      method: "GET",
      headers: {
        client_id: "6013ca1b80964f7b9b147e23f2f1a46e",
        dealerid: id,
        "content-type": "application/json",
        Authorization: "Bearer " + key,
      },
      url: tekionURL,
    };

    axios
      .request(options)
      .then(function (res) {
        var totalPages = res.data.meta.total_pages;
        var currentPage = res.data.meta.current_page;
        var nextPageKey = res.data.meta.nextPageKey;

        // printData(res.data.meta);
        items.push(res.data.data);

        if (currentPage < 2) {
          //TODO --- CHANGE IN PRODUCTION ==== PRODUCTION CHANGES BELOW
          //currentPage < totalPages
          console.log("CurrentPage = " + currentPage + " / " + totalPages);
          getAllInventoryFromAPI(key, nextPageKey, items, id, autogroup);
        } else {
          console.log("Completed Full API Call!");
          const bigData = items.flat(1);
          convertInventoryData(bigData, autogroup);
        }
      })
      .catch((error) => console.log("ERROR: " + error));
  }

  function convertInventoryData(inventory_arr, autogroup) {
    const sortedInventoryData = [];

    inventory_arr.forEach(function (inventoryItem) {
      var currentCost = 0;

      var lastSixVIN = inventoryItem.vin.slice(-6);

      if (inventoryItem.pricingDetails.invoicePrice != null) {
        currentCost = inventoryItem.pricingDetails.invoicePrice.amount;
        // currentCost = inventoryItem.pricingDetails.msrp.amount
        // currentCost = inventoryItem.pricingDetails.retailPrice.amount
      }

      //Doc Information stored in Firestore
      var invItem = {
        vin: lastSixVIN,
        fullVIN: inventoryItem.vin,
        stockNo: inventoryItem.stockId,
        make: inventoryItem.make,
        model: inventoryItem.model,
        year: inventoryItem.year,
        colour: inventoryItem.exteriorColor,
        trim: inventoryItem.trimDetails.trim,
        cost: currentCost,
      };

      sortedInventoryData.push(invItem);
    });

    setFetchDB(false);
    setInventory(sortedInventoryData);

    console.log("Inventory Data: ", sortedInventoryData);
    console.log("Count Report: ", vins);

    setShowCalculateExceptions(true);
  }

  ************************************/

  const calculateExceptions = () => {
    setFetchDB(true);
    setLoadingText("Calculating Exceptions...");
    console.log("--Exception Arrays--");

    const filtered_overs = vins.filter(function (vehicle) {
      return !inventory.some(function (inventoryVin) {
        return vehicle.vin === inventoryVin.vin;
      });
    });

    const filtered_unders = inventory.filter(function (inventoryVin) {
      return !vins.some(function (vehicle) {
        return inventoryVin.vin === vehicle.vin;
      });
    });

    filtered_unders.forEach(function (underItem) {
      if (underItem.cost !== undefined) {
        underItem.cost = "$" + underItem.cost;
      } else {
        underItem.cost = "n/a";
      }
    });

    vins.map((itemCount) => {
      var itemFromInv = inventory.find(
        (itemInv) => itemInv.vin === itemCount.vin
      );

      if (itemFromInv) {
        itemCount.make = itemFromInv.make;
        itemCount.model = itemFromInv.model;
        itemCount.year = itemFromInv.year;
        itemCount.cost = itemFromInv.cost;
        console.log("Match Found!: " + itemCount.vin);
      }

      //ADD TO REMOVE - Array.prototype.map() expects a return value from arrow function
      return itemFromInv; //MAY NEED TO CHANGE/DELETE
      //************************************ */
    });

    console.log("Unders: ", filtered_unders);
    console.log("Unders: ", filtered_overs);
    console.log("New Count Report: ", vins);

    setUnders(filtered_unders);
    setOvers(filtered_overs);

    //Set View and Tables for Results
    setFetchDB(false);
    setShowCalculateExceptions(false);
    setShowSaveExceptions(true);
  };

  const handleSaveToDB = () => {
    saveExceptionsReport();
  };

  async function saveExceptionsReport() {
    setFetchDB(true);
    setLoadingText("Calculating Exceptions...");

    const batch = writeBatch(db);

    const time = getTodaysTime();
    const date = getTodaysDate();

    //Update Count Report with Make,Model,Year,Cost
    vins.forEach(function (countItem) {
      var countDocRef = doc(
        db,
        "orgs",
        userInfo.org,
        "countReport",
        countItem.vin
      );

      if (countItem.make) {
        batch.set(countDocRef, countItem, { merge: true });
      } else if (countItem.model) {
        batch.set(countDocRef, countItem, { merge: true });
      } else if (countItem.year) {
        batch.set(countDocRef, countItem, { merge: true });
      } else if (countItem.cost) {
        batch.set(countDocRef, countItem, { merge: true });
      }
    });

    //Upload Overs
    overs.forEach(function (overItem) {
      var orgsExceptionOverRef = doc(
        db,
        "orgs",
        userInfo.org,
        "oversReport",
        overItem.vin
      );

      var exceptionOver = {
        vin: overItem.vin,
        locationOver: location || "",
        isOver: true,
      };

      batch.set(orgsExceptionOverRef, exceptionOver, { merge: true });
    });

    //Upload Unders
    unders.forEach(function (underItem) {
      var orgsExceptionUnderRef = doc(
        db,
        "orgs",
        userInfo.org,
        "undersReport",
        underItem.vin
      );

      var exceptionUnder = {
        vin: underItem.vin,
        locationUnder: location || "",
        isUnder: true,
        make: underItem.make || "",
        model: underItem.model || "",
        year: underItem.year || "",
        cost: underItem.cost || 0,
      };
      batch.set(orgsExceptionUnderRef, exceptionUnder, { merge: true });
    });

    //Upload Exception Document
    const orgDetailsRef = doc(db, "orgs", userInfo.org);
    batch.update(orgDetailsRef, {
      except_time_updated: time,
      except_date_updated: date,
      exceptionCompleted: true,
    });

    //Update Location Exception Report
    const locationDetailRef = doc(db, "orgs", userInfo.org, "lots", location);
    batch.update(locationDetailRef, {
      exceptionCompleted: true,
      except_num_of_overs: overs.length,
      except_num_of_unders: unders.length,
      except_num_of_total: overs.length + unders.length,
      exceptionDate: date,
      exceptionTime: time,
    });

    await batch.commit().then(function () {
      //Set View and Tables for Results
      setFetchDB(false);
      setEntryDivs(false);
      setShowCalcDiv(false);
      setShowSaveExceptions(false);
      setShowResults(true);
      console.log("Done! - Check Database");
    });
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  const exportExcel = (exportArray, filename) => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, filename);
    });
  };

  function saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      let date = getTodaysDate();
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + date + EXCEL_EXTENSION);
    });
  }

  function getLastCleared(clearDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    const dateArr = clearDate.split("-");
    const firstDate = new Date(
      dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0]
    );
    const secondDate = new Date();
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    const returnDate = diffDays - 1;

    var newDate = "";
    if (returnDate === 0) {
      newDate = "Today. (" + clearDate + ")";
    } else if (returnDate === 1) {
      newDate = returnDate + " day ago (" + clearDate + ")";
    } else {
      newDate = returnDate + " days ago (" + clearDate + ")";
    }

    return newDate;
  }

  function goToReports() {
    history.push("count");
  }

  function goToExceptions() {
    history.go(0);
  }

  //HTML
  const SelectLocationDiv = () => (
    <div id="selectLocation" className="mb-3">
      <Card id="locationCard" className="exceptCards">
        <Card.Title class="d-flex justify-content-between align-items-center">
          <h5>Exception Report Last Cleared {lastUpdated}</h5>

          <button
            type="button"
            class="btn btn-primary py-9px btn-right"
            onClick={() => goToReports()}
          >
            Clear Found Exceptions
          </button>
        </Card.Title>
        <Card.Footer>
          <div class="d-flex justify-content-start">
            <i class="pi pi-exclamation-triangle warn-icon"></i>
            <p class="mt-2 report-subtext">
              Please ensure the tables are empty for the Overs/Unders in Reports
              when starting a new month's Count.
            </p>
          </div>
        </Card.Footer>
      </Card>

      <Card id="locationCard" className="exceptCards">
        <Card.Title>Choose a Location</Card.Title>
        <Card.Body class="col-md-3 d-flex justify-content-start align-content-around">
          {lots.map((lot) => (
            <button
              disabled={!lot.countCompleted}
              type="button"
              class="btn btn-primary py-9px btn-exceptions"
              style={{
                backgroundColor: lot.exceptionCompleted ? "#50C878" : "#6B75CC",
              }}
              onClick={() => handleLocation(lot.name)}
            >
              {lot.abbreviation}
            </button>
          ))}
        </Card.Body>
        {/* <Card.Footer>
          <div>
            <p class="mt-2 report-subtext">Location Selected: {location}</p>
          </div>
        </Card.Footer> */}
      </Card>
    </div>
  );

  const CalculateButtonDiv = () => (
    <div>
      <button
        type="button"
        class="btn btn-primary w-full w-50 py-9px"
        onClick={calculateExceptions}
        disabled={inventory.length === 0}
      >
        Cacluate Exceptions
      </button>
    </div>
  );

  const SaveButtonDiv = () => (
    <div>
      <button
        type="button"
        class="btn btn-primary w-full w-50 py-9px"
        onClick={handleSaveToDB}
      >
        Save Exceptions
      </button>
    </div>
  );

  const FetchInventoryInfoDiv = () => (
    <div class="mt-3">
      <img src="../icons/icon-update.svg" alt="" />
      <div class="d-flex align-items-center justify-content-center">
        <p class="support-card__subtitle mb-3">
          Please allow a few minutes for the Report to Upload.
        </p>
      </div>
    </div>
  );

  const CalcButtonDiv = () => (
    <div id="calcButton d-flex flex-column" className="mb-3">
      <Card id="calcButtonCard" className="exceptCards">
        <Card.Title>Calculate Exceptions Report</Card.Title>
        <Card.Body className="center">
          <>
            <div>
              <img src="../icons/icon-location.svg" alt="" />
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="support-card__subtitle mb-3">
                  Location: {location}{" "}
                </h4>
              </div>
            </div>

            {!showCalculateExceptions && !showSaveExceptions ? (
              <FetchInventoryInfoDiv />
            ) : null}

            {showCalculateExceptions ? <CalculateButtonDiv /> : null}
          </>
        </Card.Body>
      </Card>
    </div>
  );

  const SaveResultsDiv = () => (
    <div id="d-flex flex-column" className="mt-3">
      <Card id="calcButtonCard" className="exceptCards">
        <Card.Title>Upload & Save Exceptions Report</Card.Title>
        <Card.Body className="center">
          <>
            <SaveButtonDiv />
          </>
        </Card.Body>
      </Card>
    </div>
  );

  const InputFileDiv = () => (
    <div id="inputFile" className="mb-3">
      <Card id="inputFileCard" className="exceptCards">
        <Card.Title>Input a DMS File</Card.Title>
        <Card.Body>
          <div class="upload-area">
            <h3 class="upload-area__title">Upload DMS Report</h3>
            <p class="upload-area__subtitle">
              <span>
                Click "Choose File" to upload an Excel file of your DMS report.
              </span>
              <a
                href="https://tapngo-my.sharepoint.com/:b:/g/personal/indy_tapngo_tech/EVTR-fvPaDZHq64qXg8Zx94BMu7-FeBFALRN5uucdVZpmA?e=WvOa5P"
                download="DMS Excel Guidelines.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <img alt="info" src={infoIcon} />
              </a>
            </p>
            <input
              type="file"
              onChange={fileHandler}
              id="inputDMSfile"
              className="d-none"
            />
            <label
              htmlFor="inputDMSfile"
              class="btn btn-primary w-full w-100 py-9px"
              style={{ maxWidth: 235 }}
            >
              Choose File
            </label>

            <small class="upload-area__small d-block">
              Supported files: .xls or .xlsx
            </small>
          </div>
        </Card.Body>
      </Card>
    </div>
  );

  const InfoDiv = () => (
    <div class="d-flex justify-content-between align-items-center">
      <p class="report-subtext">
        Overs: {overs.length} - Unders: {unders.length}{" "}
      </p>

      <button
        type="button"
        class="btn btn-primary py-9px btn-right"
        onClick={() => goToExceptions()}
      >
        Create New Exceptions
      </button>
    </div>
  );

  const oversHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">Overs Report</h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {overs.length}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(overs, "Overs_Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const undersHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">Unders Report</h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {unders.length}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(unders, "Unders_Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const countHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">Count Report</h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {vins.length}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(vins, "Count_Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const invHeader = (
    <>
      <div
        class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
        style={{ marginTop: 1.75, marginBottom: 1.75 }}
      >
        <div class="d-flex flex-column">
          <h4 class="d-flex justify-content-start">Inventory Report</h4>
          <p class="d-flex justify-content-start report-subtext b-subtext mt-1 mb-2">
            Total: {inventory.length}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
        <button
          type="button"
          className="btn btn-secondary w-full py-9px d-flex align-items-center"
          style={{ maxWidth: 100, gap: 10 }}
          onClick={() => exportExcel(inventory, "Inventory_Report")}
        >
          <img src={downloadIcon} alt="" />
          <span>EXCEL</span>
        </button>
      </div>
    </>
  );

  const ResultsDiv = () => (
    <div id="resultTables">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Overs">
          <DataTable
            id="oversTable"
            className="exceptTables"
            value={overs}
            paginator
            stripedRows
            rows={25}
            header={oversHeader}
            // globalFilter={globalFilter}
            emptyMessage="No Overs Founds"
          >
            {oversColumns}
          </DataTable>
        </TabPanel>

        <TabPanel header="Unders">
          <DataTable
            id="undersTable"
            className="exceptTables"
            value={unders}
            paginator
            stripedRows
            rows={25}
            header={undersHeader}
            // globalFilter={globalFilter}
            emptyMessage="No unders Founds"
          >
            {inventoryColumns}
          </DataTable>
        </TabPanel>

        <TabPanel header="Count Report">
          <DataTable
            id="countExceptionTable"
            className="exceptTables"
            value={vins}
            paginator
            stripedRows
            rows={25}
            header={countHeader}
            // globalFilter={globalFilter}
          >
            {countColumns}
          </DataTable>
        </TabPanel>

        <TabPanel header="Inventory Report">
          <DataTable
            id="invExceptionTable"
            className="exceptTables"
            value={inventory}
            paginator
            stripedRows
            rows={25}
            header={invHeader}
            // globalFilter={globalFilter}
          >
            {inventoryColumns}
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );

  const exceptionsHeader = (
    <>
      <div class="d-flex flex-column marginCover">
        <h2 class="report-title text-left mb-1">Exceptions</h2>
        <p class="location-subheader">{subHeader}</p>
        {showResults ? <InfoDiv /> : null}
      </div>
    </>
  );

  return (
    <Container>
      {exceptionsHeader}

      {showEntryDivs ? <SelectLocationDiv /> : null}

      {showExcelDiv ? <InputFileDiv /> : null}

      {showCalcDiv ? <CalcButtonDiv /> : null}

      {showSaveExceptions ? <SaveResultsDiv /> : null}

      {showResults ? <ResultsDiv /> : null}
    </Container>
  );
}

export default Exceptions;
