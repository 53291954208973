import React, { useEffect, useState, useContext } from "react";
import { Container, Modal } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { AuthContext } from "../contexts/Auth";
import { db } from "../Firebase.js";
import {
  getDocs,
  collection,
  query,
  limit,
  writeBatch,
  doc,
  updateDoc,
} from "firebase/firestore";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/count.css";

import { DoubleBubble } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import searchIcon from "../images/icons/icon-search-primary.svg";
import downloadIcon from "../images/icons/icon-upload-primary.svg";

function LotReport() {
  const history = useHistory();
  const params = useParams();

  const [fetchDB, setFetchDB] = useState(true);
  const [loadingText, setLoadingText] = useState("");

  const { userInfo } = useContext(AuthContext);
  const [vins, setVins] = useState([]);

  const [globalFilter, setGlobalFilter] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const handleDeleteWarningClose = () => setShowDeleteWarning(false);

  var today = new Date();
  const date =
    today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();

  const cols = [
    { field: "vin", header: "Vin" },
    { field: "entry", header: "Entry" },
    { field: "date", header: "Date" },
    { field: "time", header: "Time" },
    // { field: "userID", header: "User" },
    { field: "locOrigin", header: "Location" },
    { field: "note", header: "Note" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  useEffect(() => {
    setLoadingText("Fetching Count");
    setFetchDB(true);
    if (userInfo !== undefined) {
      getLotReport(params.autogroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, params.autogroup]);

  async function getLotReport(autogroup) {
    const items = [];
    console.log(userInfo.org);
    const docRef = collection(
      db,
      "orgs",
      userInfo.org,
      "lots",
      autogroup,
      "countReport"
    );
    console.log(autogroup);
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    console.log(items);
    setVins(items);
    setFetchDB(false);
  }

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, vins);
        doc.save(params.autogroup + "_Count_" + date + ".pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(vins);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const filename = params.autogroup + " Count";
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, filename);
    });
  };

  function saveAsExcelFile(buffer, fileName) {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_" + date + EXCEL_EXTENSION);
    });
  }

  function getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  }

  function getTodaysTime() {
    var time = new Date();
    var hh = String(time.getHours()).padStart(2, "0");
    var mm = String(time.getMinutes()).padStart(2, "0");
    return hh + ":" + mm;
  }

  async function setupDeleteCount() {
    const collRef = collection(
      db,
      "orgs",
      userInfo.org,
      "lots",
      params.autogroup,
      "countReport"
    );
    const q = query(collRef, limit(vins.size));
    const time = getTodaysTime();
    const date = getTodaysDate();

    setLoadingText("Deleting Count");
    setFetchDB(true);
    return new Promise((resolve) => {
      deleteCountCollection(db, q, vins.size, resolve).then(function () {
        const lotDetailsRef = doc(
          db,
          "orgs",
          userInfo.org,
          "lots",
          params.autogroup
        );
        updateDoc(lotDetailsRef, {
          pc_num_of_count: 0,
          pc_num_of_manual: 0,
          pc_num_of_scanned: 0,
          num_of_issues: 0,
          pc_time_updated: time,
          pc_date_updated: date,
          countCompleted: false,
          has_issues: false,
          exceptionCompleted: false,
        }).then(function () {
          setFetchDB(false);
          // history.go(0);
          history.push("/reports");
        });
      });
    });
  }

  //Delete Collection
  async function deleteCountCollection(db, query, batchSize, resolve) {
    const snapshot = await getDocs(query);

    let numDeleted = 0;
    if (snapshot.size > 0) {
      // Delete documents in a batch
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      numDeleted = snapshot.size;
    }

    if (numDeleted < batchSize) {
      resolve();
      return;
    }

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    setTimeout(() => {
      deleteCountCollection(db, query, batchSize, resolve);
    }, 0);
  }
  //End Delete Collection

  const showDeleteModal = () => {
    setShowDeleteWarning(true);
  };

  if (showDeleteWarning) {
    return (
      <Modal
        size="lg"
        show={showDeleteWarning}
        onHide={handleDeleteWarningClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Delete Count for {params.autogroup}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this month's count. This will not
          delete VINs that are in the Found Exceptions Report.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={handleDeleteWarningClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger w-full py-9px"
            style={{ minWidth: 313 }}
            onClick={setupDeleteCount}
          >
            Delete Count
          </button>

        </Modal.Footer>
      </Modal>
    );
  }

  if (fetchDB) {
    return (
      <DoubleBubble
        text={loadingText}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    );
  }

  const emptyMessage = (
    <div className="md-4 text-center">
      <h4>No Results Found</h4>
    </div>
  );

  const lotHeader = (
    <>
      <div>
        <div
          class="d-flex justify-content-md-between flex-wrap text-center text-md-left"
          style={{ marginTop: 1.75 }}
        >
          <div class="d-flex flex-column">
            <h4 class="d-flex justify-content-start">
              {params.autogroup} Count Report
            </h4>
            <p class="report-subtext mt-1">
              Please "Clear Physical Count" before starting the next Count.
            </p>
          </div>

          <div class="d-flex align-items-center mt-3 mt-md-0">
            <button
              type="button"
              className="btn btn-primary w-full py-9px"
              style={{ minWidth: 313 }}
              onClick={showDeleteModal}
            >
              Clear Physical Count{" "}
            </button>
          </div>
        </div>

        <div class="d-flex justify-content-md-between justify-content-center algin-items-center flex-wrap mt-3">
          <div
            class="form-group has-search w-100 mb-0"
            style={{ maxWidth: 373 }}
          >
            <img src={searchIcon} class="form-control-feedback" alt="" />
            <input
              type="text"
              class="form-control form-search"
              placeholder="Search..."
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>

          <div class="d-flex mt-3 mt-md-0" style={{ gap: 10 }}>
            <button
              type="button"
              className="btn btn-secondary w-full py-9px d-flex align-items-center"
              style={{ maxWidth: 100, gap: 10 }}
              onClick={exportExcel}
            >
              <img src={downloadIcon} alt="" />
              <span>EXCEL</span>
            </button>

            <button
              type="button"
              icon="pi pi-file-excel"
              className="btn btn-secondary w-full py-9px d-flex align-items-center"
              style={{ maxWidth: 100, gap: 10 }}
              onClick={exportPdf}
            >
              <img src={downloadIcon} alt="" />
              <span>PDF</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Container>
      <div className="physical-count-datatable">
        {lotHeader}
        <DataTable
          id="countTable"
          value={vins}
          paginator
          rows={10}
          stripedRows 
          // header={header}
          globalFilter={globalFilter}
          emptyMessage={emptyMessage}
        >
          <Column field="vin" header="VIN" sortable></Column>
          <Column field="entry" header="Entry" sortable></Column>
          <Column field="date" header="Date" sortable></Column>
          <Column field="time" header="Time" sortable></Column>
          <Column field="locOrigin" header="Location" sortable></Column>
          <Column field="note" header="Issue" sortable></Column>
          {/* <Column field="userID" header="User" sortable></Column> */}
        </DataTable>
      </div>
    </Container>
  );
}

export default LotReport;
