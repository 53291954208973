import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";
import AddIssue from "./components/AddIssue";
import Login from "./components/Login";

import Exceptions from "./components/Exceptions";
import Count from "./components/Count";
import Reports from "./components/Reports";
import LotReport from "./components/LotReport";
import Inventory from "./components/Inventory";
import InventoryLocations from "./components/InventoryLocations"
import IssueReport from "./components/IssueReport";


import { AuthProvider } from "./contexts/Auth";
import PrivateRoute from "./components/PrivateRoute";
import HomeNavbar from "./components/HomeNavbar";
// import "bootstrap/dist/css/bootstrap.min.css";

import "./scss/style.scss";

function App() {
  return (
    <AuthProvider>
      <Router>
        <HomeNavbar />
        <div>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/add_issue" component={AddIssue} />
          <Route exact path="/login" component={Login} />

          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/count_report/:autogroup" component={LotReport} />
          <PrivateRoute exact path="/issue_report/:autogroup" component={IssueReport} />

          <PrivateRoute exact path="/count" component={Count} />
          <PrivateRoute exact path="/exceptions" component={Exceptions} />
          <PrivateRoute exact path="/inventory" component={InventoryLocations} />
          <PrivateRoute exact path="/inventory_report/:autogroup/:id" component={Inventory} />

        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
